import type { Geolocation } from '@lib/types/Geolocation'
import calculateDistance from '@lib/utilities/calculateDistance'
import type { TrialLocation } from '@prisma/client'
import uniq from 'lodash/uniq'
import { hasAvailableCountry } from '../hasAvailableCountry'
import { usStateCodes } from './usStateCodes'

export const cityStatecodeString = (location: SortableLocation) => {
  const stateCode =
    usStateCodes.find((state) => state.name === location.state)?.abbreviation ??
    location.country

  return `${location.city}${stateCode ? `, ${stateCode}` : ''}`
}

export function findClosestLocation<T extends SortableLocation>(
  locations: T[],
  viewerLocation: Geolocation,
) {
  const validLocations = locations.filter((location) =>
    hasAvailableCountry(location.country),
  )
  if (validLocations.length === 0) {
    return undefined
  }

  const closestLocationData = {
    distance:
      calculateDistance(locationToPoint(validLocations[0]!), viewerLocation) ??
      Number.MAX_SAFE_INTEGER,
    location: locations[0],
  }

  const remainingLocations = validLocations.slice(1)

  for (const location of remainingLocations) {
    const distance = calculateDistance(
      locationToPoint(location),
      viewerLocation,
    )

    if (distance === undefined) {
      continue
    }

    if (distance < closestLocationData.distance) {
      closestLocationData.distance = distance
      closestLocationData.location = location
    }
  }

  return closestLocationData
}

export function sortLocationsByDistance<T extends SortableLocation>(
  locations: T[],
  viewerLocation: Geolocation,
  distanceWillingToTravelInKm?: number,
) {
  locations.sort((a, b) => {
    const distanceA = calculateDistance(locationToPoint(a), viewerLocation)
    const distanceB = calculateDistance(locationToPoint(b), viewerLocation)

    if (distanceA === undefined && distanceB === undefined) return 0
    if (distanceA === undefined) return 1
    if (distanceB === undefined) return -1

    return distanceA - distanceB
  })

  // filter trial to within distance and if distance is undefined we don't show that location
  // if this filters out all locations then we resort to showing all locations
  if (distanceWillingToTravelInKm) {
    const distanceFilteredLocations = locations.filter((location) => {
      const distance = calculateDistance(
        locationToPoint(location),
        viewerLocation,
      )
      return distance !== undefined && distance <= distanceWillingToTravelInKm
    })
    if (distanceFilteredLocations.length > 0) {
      return distanceFilteredLocations
    }
  }

  return locations
}

type SortableLocation = Pick<
  TrialLocation,
  'city' | 'country' | 'lat' | 'lng' | 'state'
>

export function getSortedLocations<T extends SortableLocation>(
  trialLocations: T[],
  userLocation?: Geolocation,
) {
  const availableTrialLocations = trialLocations.filter((location) =>
    hasAvailableCountry(location.country),
  )

  if (!userLocation) {
    return availableTrialLocations as T[]
  }

  const sortedLocations = sortLocationsByDistance(
    availableTrialLocations,
    userLocation,
  )

  return sortedLocations as T[]
}

export const parseTruncatedLocationsFromTrial = (
  trialLocations: SortableLocation[],
  userLocation?: Geolocation,
): string => {
  const locations = getSortedLocations(trialLocations, userLocation)
  return concatenateLocationString(locations)
}

function concatenateLocationString(locations: SortableLocation[]): string {
  const uniqueLocations = uniq(locations.map(cityStatecodeString))
  const locationsText = uniqueLocations.slice(0, 1)

  const truncatedLocations =
    uniqueLocations.slice(1).length !== 0
      ? ` + ${uniqueLocations.slice(1).length} More`
      : ''

  // TODO: +more should expand to the more locations!?

  return locationsText + truncatedLocations
}

export const locationToPoint = (location: {
  lat: string | null
  lng: string | null
}): Geolocation => {
  return {
    latitude: parseFloat(location.lat ?? ''),
    longitude: parseFloat(location.lng ?? ''),
  }
}
